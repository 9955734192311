import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React, { useMemo, useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store';
import Theme from 'components/template/Theme';
import Layout from 'components/layout';
import history from './history';
import mockServer from './mock';
import appConfig from 'configs/app.config';
import './locales';
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  ApolloProvider,
  concat,
  split,
} from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';

const environment = process.env.NODE_ENV;

/**
 * Set enableMock(Default false) to true at configs/app.config.js
 * If you wish to enable mock api
 */
if (environment !== 'production') {
  if (appConfig.enableMock) {
    mockServer({ environment });
  }

  loadDevMessages();
  loadErrorMessages();
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ClientContainer>
          <BrowserRouter history={history}>
            <Theme>
              <Layout />
            </Theme>
          </BrowserRouter>
        </ClientContainer>
      </PersistGate>
    </Provider>
  );
}

export const ClientContainer = ({ children }) => {
  const uri = 'https://drderma.hasura.app/v1/graphql';
  const [token, setToken] = useState(localStorage.getItem('token'));

  useEffect(() => {
    window.addEventListener(
      'storage',
      () => {
        setToken(localStorage.getItem('token'));
      },
      false
    );
  }, []);

  const client = useMemo(() => {
    let headers = {
      'content-type': 'application/json',
      'x-hasura-admin-secret':
        'SBxfxz9zoW9zIDwXmS470bDKp7ml80PUbsvI45xRC1kh0T956Jwf41aQEOAEOKSF',
    };
    if (token) headers.Authorization = `Bearer ${token}`;

    const httpLink = new HttpLink({ uri });
    const wsLink = new GraphQLWsLink(
      createClient({
        url: 'wss://drderma.hasura.app/v1/graphql',
        connectionParams: {
          headers,
        },
      })
    );

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink
    );

    const authMiddleware = new ApolloLink((operation, forward) => {
      operation.setContext(({ headers: ctxHeaders = {} }) => ({
        headers: {
          ...ctxHeaders,
          ...headers,
        },
      }));
      return forward(operation);
    });

    return new ApolloClient({
      uri,
      cache: new InMemoryCache(),
      link: concat(authMiddleware, splitLink),
    });
  }, [token]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default App;
