import React from 'react';

const checkoutRoutes = [
  {
    key: 'checkout',
    path: `/checkout`,
    component: React.lazy(() => import('views/checkout/CheckoutFlow')),
    authority: [],
  },
];

export default checkoutRoutes;
