import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyA16-KkPJpjmg9y7itKlwVSqc7tI_x-d7w',
  authDomain: 'drderma-b1afc.firebaseapp.com',
  projectId: 'drderma-b1afc',
  storageBucket: 'drderma-b1afc.appspot.com',
  messagingSenderId: '446090021229',
  appId: '1:446090021229:web:237279e4d9f0112276f264',
  measurementId: 'G-T1JZYS0DNN',
};

const app = firebase.initializeApp(firebaseConfig);

export const auth = app.auth();
export default app;
