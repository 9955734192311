import React, { memo, useMemo, lazy, Suspense } from 'react';
import { Loading } from 'components/shared';
import { useSelector } from 'react-redux';
import {
  LAYOUT_TYPE_CLASSIC,
  LAYOUT_TYPE_MODERN,
  LAYOUT_TYPE_SIMPLE,
  LAYOUT_TYPE_STACKED_SIDE,
  LAYOUT_TYPE_DECKED,
  LAYOUT_TYPE_BLANK,
} from 'constants/theme.constant';
import useAuth from 'utils/hooks/useAuth';
import useDirection from 'utils/hooks/useDirection';
import useLocale from 'utils/hooks/useLocale';
import siteRoutes from 'configs/routes.config/siteRoutes';
import checkoutRoute from 'configs/routes.config/checkoutRoute';
import { useLocation } from 'react-router';

const layouts = {
  [LAYOUT_TYPE_CLASSIC]: lazy(() => import('./ClassicLayout')),
  [LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
  [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import('./StackedSideLayout')),
  [LAYOUT_TYPE_SIMPLE]: lazy(() => import('./SimpleLayout')),
  [LAYOUT_TYPE_DECKED]: lazy(() => import('./DeckedLayout')),
  [LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
};

const Layout = () => {
  const layoutType = useSelector((state) => state.theme.layout.type);

  const { authenticated, loading } = useAuth();
  const location = useLocation();
  const pathname = location.pathname.split('/')[1];

  useDirection();

  useLocale();

  const AppLayout = useMemo(() => {
    if (loading) {
      return () => (
        <div className="h-[100vh] flex items-center justify-center">
          <Loading loading={true} />
        </div>
      );
    }

    console.log(
      authenticated,
      !siteRoutes.find((route) => route.path.includes(pathname)),
      !checkoutRoute.find((route) => route.path.includes(pathname))
    );

    if (
      authenticated &&
      !siteRoutes.find((route) => route.path.includes(pathname)) &&
      !checkoutRoute.find((route) => route.path.includes(pathname))
    ) {
      return layouts[layoutType];
    }

    return lazy(() => import('./AuthLayout'));
  }, [layoutType, authenticated, loading, pathname]);

  return (
    <Suspense
      fallback={
        <div className="flex flex-auto flex-col h-[100vh]">
          <Loading loading={true} />
        </div>
      }
    >
      <AppLayout />
    </Suspense>
  );
};

export default memo(Layout);
