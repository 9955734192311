const appConfig = {
  apiPrefix: '/api',
  authenticatedEntryPath: '/admin/consultas',
  unAuthenticatedEntryPath: '/sign-in',
  tourPath: '/',
  locale: 'en',
  enableMock: true,
};

export default appConfig;
